*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Page transition */
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

/* Masonry */
.my-masonry-grid {
  display: flex;
  margin-left: -10px; /* Adjust based on the gap */
  gap: 40px;
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 10px; /* Adjust based on the gap */
  background-clip: padding-box;
}

.my-masonry-grid_column > div {
  margin-bottom: 10px; /* Adjust based on the gap */
}
